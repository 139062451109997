import { Box } from "@material-ui/core";
import React, { useEffect, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import ApplicationState from "../models/application-state";
import SocketContext from "../context/socket-context";
import GameService from "../services/game-service";
import { outgoingMessageTypes } from "../constants/message-types";

const GameScreen: React.FC = () => {
  const selectedGame = useSelector((state: ApplicationState) => state.selectedGame);
  const socket = useContext(SocketContext);
  const gameArea = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    GameService.setGameWindow(gameArea.current?.contentWindow);
    GameService.onGameReady(() => socket?.emit(outgoingMessageTypes.gameReady));
    GameService.onGameOver(() => socket?.emit(outgoingMessageTypes.gameOver));
  }, []);

  if (!selectedGame) {
    return <Navigate to="/" />;
  }

  return (
    <Box width={1} overflow="hidden">
      <Box position="relative">
        <iframe
          ref={gameArea}
          title="game-area"
          src={selectedGame.path}
          style={{
            border: 0,
            padding: 0,
            margin: 0,
            width: "100%",
            height: "100vh",
          }}
        />
      </Box>
    </Box>
  );
};

export default GameScreen;
