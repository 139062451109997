import { applyMiddleware, compose, createStore, Store } from "redux";
import { withExtraArgument } from "redux-thunk";
import reducers from "./reducers";

export const configureStore = (emit: Function): Store => {
  const middleware = [withExtraArgument({ emit })];
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return createStore(
    reducers,
    {
      code: null,
      selectedGame: null,
      sbcStatus: "offline",
      gameList: [],
      playerList: [],
    },
    composeEnhancers(applyMiddleware(...middleware)),
  );
};
