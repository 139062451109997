import { Reducer } from "redux";
import { UPDATE_PLAYER_LIST } from "../actions/action-types";

const playerList: Reducer = (state = [], action) => {
  switch (action.type) {
    case UPDATE_PLAYER_LIST:
      return action.players;
    default:
      return state;
  }
};

export default playerList;
