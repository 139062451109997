import { Store } from "redux";
import io, { Socket } from "socket.io-client";
import { NavigateFunction } from "react-router-dom";
import { incomingMessageTypes } from "../constants/message-types";
import { URI } from "../constants/config";
import { EventHandler } from "./event-handler";

export const initSocket = (serialNumber: string): Socket =>
  io(URI, { query: { type: "display", token: serialNumber } });

export const initEventHandler = (store: Store | null, navigate: NavigateFunction, socket: Socket) => {
  if (!store) return;

  const emit = (type: string, payload?: any) => socket.emit(type, payload);
  const handler = new EventHandler(store, navigate, emit);
  Object.values(incomingMessageTypes).forEach((message) => {
    socket.on(message, (payload: any) => handler.handle(message, payload));
  });
};
