import React, { useMemo } from "react";
import Slider from "react-slick";

interface CarouselProps {
  items: React.ReactNode[];
}

export const PlayersCarousel: React.FC<CarouselProps> = ({ items }) => {
  const settings = useMemo(() => {
    return {
      dots: false,
      infinite: true,
      speed: 250,
      slidesToScroll: 1,
      slidesToShow: 5,
      autoplay: true,
      arrows: false,
      autoplaySpeed: 2000,
    };
  }, [items.length]);

  return (
    <Slider vertical {...settings}>
      {items}
    </Slider>
  );
};
