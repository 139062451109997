import React, { useCallback, useMemo } from "react";
import Slider from "react-slick";

interface CarouselProps {
  items: React.ReactNode[];
}

export const GamesCarousel: React.FC<CarouselProps> = ({ items }) => {
  const setSlidesToShow = useCallback(() => {
    if (window.innerWidth >= 2400) {
      return 5;
    } else if (window.innerWidth >= 1920) {
      return 4;
    } else if (window.innerWidth >= 1360) {
      return 3;
    } else {
      return 2;
    }
  }, [window.innerWidth]);

  const settings = useMemo(() => {
    return {
      dots: false,
      infinite: items.length > 5,
      speed: 250,
      slidesToScroll: 5,
      slidesToShow: setSlidesToShow(),
      autoplay: true,
      arrows: false,
    };
  }, [items.length, setSlidesToShow]);

  return <Slider {...settings}>{items}</Slider>;
};
