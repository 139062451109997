import { Reducer } from "redux";
import { SET_CODE } from "../actions/action-types";

const code: Reducer = (state = null, action) => {
  switch (action.type) {
    case SET_CODE:
      return action.code;
    default:
      return state;
  }
};

export default code;
