import { Box, Container, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { PureComponent } from "react";

export class GameSkeleton extends PureComponent {
  render() {
    return (
      <Container maxWidth="xl">
        <Box mx={2} mb={2} width="45%">
          <Skeleton animation={false} variant="text" height="95px" />
        </Box>
        <Grid container={true} wrap="nowrap">
          <Box m={2} width="30%">
            <Skeleton animation={false} variant="rect" height="300px" />
          </Box>
          <Box m={2} mt={5} width="40%" height="20px">
            <Skeleton animation={false} variant="text" height="30px" width="25%" />
            <Skeleton animation={false} variant="text" height="30px" />
            <Skeleton animation={false} variant="text" height="30px" width="50%" />
            <Skeleton animation={false} variant="text" height="30px" width="70%" />
          </Box>
        </Grid>
      </Container>
    );
  }
}
