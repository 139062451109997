import { Store } from "redux";
import { NavigateFunction } from "react-router-dom";
import GameService from "../services/game-service";
import { reset, selectGame, setCode, sbcStatusChanged, updatePlayerList } from "../store/actions/action-creators";
import { incomingMessageTypes, outgoingMessageTypes } from "../constants/message-types";
import GameConfiguration from "../models/game-configuration";
import SbcStatus from "../models/sbc-status";
import SbcRegistrationError from "../models/sbcRegistrationError";
import { SERIAL_NUMBER } from "../App";
import Player from "../models/player";

export class EventHandler {
  private linkToken = "";

  constructor(
    private store: Store,
    private navigate: NavigateFunction,
    private emit: (message: string, payload?: any) => void,
  ) {}

  public handle(event: string, payload?: any): void {
    switch (event) {
      case incomingMessageTypes.gameConfigurationChanged:
        this.onGameConfigurationChanged(payload);
        break;
      case incomingMessageTypes.unlink:
        this.onUnlink();
        break;
      case incomingMessageTypes.linkRequest:
        this.onLinkRequest();
        break;
      case incomingMessageTypes.codeResponse:
        this.onCodeResponse(payload);
        break;
      case incomingMessageTypes.selectGameRequest:
        this.onSelectGameRequest(payload);
        break;
      case incomingMessageTypes.startGame:
        this.navigate("/game");
        this.onStartGame();
        break;
      case incomingMessageTypes.abortGame:
        this.onAbortGame();
        break;
      case incomingMessageTypes.listGamesRequest:
        this.onListGamesRequest();
        break;
      case incomingMessageTypes.throw:
        GameService.throwDetected(payload);
        break;
      case incomingMessageTypes.takeoutStarted:
        GameService.takeoutStarted(payload);
        break;
      case incomingMessageTypes.takeoutFinished:
        GameService.takeoutFinished(payload);
        break;
      case incomingMessageTypes.loadGame:
        this.navigate("/load-game");
        break;
      case incomingMessageTypes.playerListChanged:
        GameService.playerListChanged(payload);
        this.onPlayerListChanged(payload);
        break;
      case incomingMessageTypes.sbcStatusChanged:
        this.onSbcStatusChanged(payload);
        break;
      case incomingMessageTypes.sbcRegistrationError:
        this.onSbcRegistrationError(payload);
        break;
      case incomingMessageTypes.reconnect:
        this.onReconnect();
        break;
      case incomingMessageTypes.linkResponse:
        this.onLinkResponse(payload);
        break;
      case incomingMessageTypes.connect:
        this.requestCode();
        break;
    }
  }

  private onGameConfigurationChanged(values: GameConfiguration): void {
    GameService.updateGameConfiguration(values);
  }

  private onUnlink(): void {
    GameService.reset();
    this.store.dispatch<any>(reset());
    this.navigate(`/?serialNumber=${sessionStorage.getItem(SERIAL_NUMBER)}`);
  }

  private onLinkRequest(): void {
    this.navigate("/game-list");
  }

  private onCodeResponse({ code, token }: { code: string; token: string }): void {
    this.linkToken = token;
    this.store.dispatch(setCode(code));
  }

  private onSelectGameRequest({ gameId }: { gameId: string }): void {
    this.store.dispatch<any>(selectGame(gameId));
  }

  private onStartGame(): void {
    GameService.startGame();
  }

  private onAbortGame(): void {
    GameService.reset();
    this.navigate("/game-list");
  }

  private onListGamesRequest(): void {
    const { gameList } = this.store.getState();
    this.emit(outgoingMessageTypes.listGamesResponse, gameList);
  }

  private onSbcStatusChanged(sbcStatus: SbcStatus): void {
    this.store.dispatch<any>(sbcStatusChanged(sbcStatus));
    if (sbcStatus.status === "offline") {
      GameService.reset();
      this.store.dispatch<any>(reset());
      this.emit(outgoingMessageTypes.reload);
      this.navigate(`/?serialNumber=${sessionStorage.getItem(SERIAL_NUMBER)}`);
    }
  }

  private onSbcRegistrationError(sbcRegistrationError: SbcRegistrationError): void {
    if (sbcRegistrationError.reason === "in_use") {
      this.store.dispatch<any>(sbcStatusChanged({ status: sbcRegistrationError.reason }));
    }
  }

  private onLinkResponse({ success, linkToken }: { success: boolean; linkToken: string; msg: string }): void {
    if (success) {
      this.linkToken = linkToken;
    } else {
      this.linkToken = "";
      this.onUnlink();
    }
  }

  private onReconnect(): void {
    if (this.linkToken !== "") {
      const code: string = this.linkToken;
      this.emit(outgoingMessageTypes.linkRequestWithToken, { code });
    }
  }

  private requestCode(): void {
    this.store.dispatch(setCode(null));
    this.emit(outgoingMessageTypes.codeRequest);
  }

  private onPlayerListChanged(players: Player[]): void {
    this.store.dispatch<any>(updatePlayerList(players));
  }
}
