import React from "react";
import Player from "../../models/player";
import "./player-card.css";

interface PlayerCardProps {
  player: Player;
  number: number;
}

export const PlayerCard = ({ player, number }: PlayerCardProps) => {
  return (
    <div className="card">
      <div>
        {player.image && <img className="icon" src={player.image} alt={player.name} />}
        {!player.image && (
          <div className="icon capitalLetterContainer">
            <p className="capitalLetter">{player.name.charAt(0).toLocaleUpperCase()}</p>
          </div>
        )}
      </div>
      <div>
        <h3 style={{ marginRight: "0.5rem" }}>{`${number + 1}.`}</h3>
      </div>
      <div>
        <h3>{player.name}</h3>
      </div>
    </div>
  );
};
