import React, { PureComponent } from "react";
import { Container } from "@material-ui/core";
import Game from "../models/game";
import { GamesCarousel } from "./games-carousel";
import GameListItem from "./game-list-item";

interface GameListProps {
  games: Game[];
  selectedGame?: Game;
  screenWidth?: number;
}

export class GameList extends PureComponent<GameListProps> {
  render() {
    const { games, selectedGame } = this.props;

    return (
      <Container maxWidth="xl" style={{ marginTop: "auto" }}>
        <GamesCarousel
          items={games.map((game) => (
            <GameListItem
              game={game}
              isSelected={selectedGame && selectedGame.gameId === game.gameId}
              key={game.gameId}
            />
          ))}
        />
      </Container>
    );
  }
}
